var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticStyle: { position: "fixed", top: "0", left: "0" },
      attrs: { app: "", permanent: "", absolute: "", "hide-overlay": "" },
    },
    [
      _c("ANotAllowed", {
        attrs: { notAllowed: _vm.notAllowed },
        on: { close: _vm.closeNotAllowed },
      }),
      _c(
        "v-list",
        [
          _c(
            "v-list-item-group",
            [
              _c("v-list-item", { on: { click: _vm.redirectHomePage } }, [
                _c("div", { staticClass: "d-flex flex-row my-4" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column" },
                    [
                      _c("v-img", {
                        attrs: {
                          contain: "",
                          height: "auto",
                          width: "auto",
                          src: "/images/Favicon.png",
                          "max-height": "50",
                          "max-width": "50",
                        },
                        on: { click: _vm.redirectHomePage },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "d-flex flex-column px-3" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row my-0 py-0 font-weight-bold text-h7",
                      },
                      [_vm._v(" TaxPod Vision ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row my-0 py-0" },
                      [
                        _c("v-chip", { attrs: { color: "primary" } }, [
                          _vm._v(" v " + _vm._s(_vm.$version) + " "),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c(
                "v-list-item",
                {
                  staticClass: "white mx-4 rounded",
                  attrs: { to: { name: "PageHomeDashboard" } },
                  on: { click: _vm.redirectHomePage },
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        color: "primary",
                        ligth: "",
                        size: "20",
                        dense: "",
                      },
                    },
                    [_vm._v(" mdi-view-dashboard ")]
                  ),
                  _c("v-list-item-title", { staticClass: "ml-3" }, [
                    _vm._v(" Dashboard "),
                  ]),
                ],
                1
              ),
              this.$store.getters.getRole.includes("developer")
                ? _c(
                    "v-list-item",
                    {
                      staticClass: "white mx-4 rounded",
                      attrs: { to: { name: "PageDeveloperDashboard" } },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: "primary",
                            ligth: "",
                            size: "20",
                            dense: "",
                          },
                        },
                        [_vm._v(" mdi-view-dashboard ")]
                      ),
                      _c("v-list-item-title", { staticClass: "ml-3" }, [
                        _vm._v(" Developer site "),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-list-item",
                {
                  staticClass: "white mx-4 rounded",
                  attrs: {
                    to: {
                      name: "PageTaskListUser",
                      params: { id: this.$store.getters.getUserId },
                    },
                  },
                  on: { click: _vm.redirectPersonalTask },
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        color: "primary",
                        light: "",
                        size: "20",
                        dense: "",
                      },
                    },
                    [_vm._v(" bi-clipboard-check ")]
                  ),
                  _c(
                    "v-list-item-title",
                    { staticClass: "ml-3" },
                    [
                      _c("span", [_vm._v(" My tasks ")]),
                      _c(
                        "v-chip",
                        {
                          staticClass: "ma-2",
                          attrs: { color: "primary white--text" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(this.notification.totalTask) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  staticClass: "white mx-4 rounded",
                  attrs: { to: { name: "PageAdminStaff" } },
                  on: { click: _vm.redirectStaff },
                },
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "primary", size: "20", dense: "" } },
                    [_vm._v(" bi-people ")]
                  ),
                  _c("v-list-item-title", { staticClass: "ml-3" }, [
                    _vm._v(" Staffs "),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  staticClass: "white mx-4 rounded",
                  attrs: { to: { name: "PagePicList" } },
                  on: { click: _vm.redirectPic },
                },
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "primary", size: "20", dense: "" } },
                    [_vm._v(" bi-person ")]
                  ),
                  _c("v-list-item-title", { staticClass: "ml-3" }, [
                    _vm._v(" PIC "),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  staticClass: "white mx-4 rounded",
                  attrs: { to: { name: "PageCompaniesList" } },
                  on: { click: _vm.redirectCompanies },
                },
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "primary", size: "20", dense: "" } },
                    [_vm._v(" bi-briefcase ")]
                  ),
                  _c("v-list-item-title", { staticClass: "ml-3" }, [
                    _vm._v(" Companies "),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  staticClass: "white mx-4 rounded",
                  attrs: { to: { name: "PageUserList" } },
                  on: { click: _vm.redirectUser },
                },
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "primary", size: "20", dense: "" } },
                    [_vm._v(" bi-person ")]
                  ),
                  _c("v-list-item-title", { staticClass: "ml-3" }, [
                    _vm._v(" Users "),
                  ]),
                ],
                1
              ),
              _vm.$permission.allowedAdminOperationRole(
                _vm.$store.getters.getRole
              )
                ? _c(
                    "v-list-group",
                    {
                      staticClass: "justify-center",
                      attrs: { value: true },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c(
                                  "v-list-item",
                                  { staticClass: "pl-3 pr-0 ml-0 mr-0" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color: "primary",
                                          ligth: "",
                                          size: "20",
                                          dense: "",
                                        },
                                      },
                                      [_vm._v(" bi-file-text-fill ")]
                                    ),
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "ml-2" },
                                      [_vm._v(" Subscriptions ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4283551675
                      ),
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              attrs: {
                                to: { name: "PageSubscriptionScheduler" },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-text-fill ")]
                              ),
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass: "ml-3",
                                  staticStyle: { "font-size": "15px" },
                                },
                                [_vm._v(" Scheduled ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              this.$store.getters.getFeatures.attendancelist != null
                ? _c(
                    "div",
                    [
                      this.$store.getters.getFeatures.attendancelist.status ==
                        1 ||
                      (this.$store.getters.getFeatures.attendancelist.beta ==
                        1 &&
                        this.$store.getters.getRole.includes("developer"))
                        ? _c(
                            "v-list-item",
                            {
                              staticClass: "white mx-4 rounded",
                              attrs: { to: { name: "PageWebinarList" } },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-display ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" Webinars "),
                              ]),
                            ],
                            1
                          )
                        : _c(
                            "v-list-item",
                            {
                              staticClass: "white mx-4 rounded",
                              on: { click: _vm.showNotAllowed },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-display ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" Webinars "),
                              ]),
                            ],
                            1
                          ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "white mx-4 rounded",
                          on: { click: _vm.showNotAllowed },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "primary",
                                size: "20",
                                dense: "",
                              },
                            },
                            [_vm._v(" bi-display ")]
                          ),
                          _c("v-list-item-title", { staticClass: "ml-3" }, [
                            _vm._v(" Webinars "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _vm.allowedRolesReport.some(function (role) {
                return this$1.$store.getters.getRole.includes(role)
              })
                ? _c(
                    "v-list-group",
                    {
                      attrs: { value: true },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color: "primary",
                                          light: "",
                                          size: "20",
                                          dense: "",
                                        },
                                      },
                                      [_vm._v(" bi-person-square ")]
                                    ),
                                    _c("span", { staticClass: "ml-2" }, [
                                      _vm._v(" Referral "),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1127599706
                      ),
                    },
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "white mx-4 rounded ml-8 mr-3",
                          attrs: { to: { name: "PageRewardList" } },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "primary",
                                size: "20",
                                dense: "",
                              },
                            },
                            [_vm._v(" bi-gift ")]
                          ),
                          _c("v-list-item-title", { staticClass: "ml-3" }, [
                            _vm._v(" Reward "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "white mx-4 rounded ml-8 mr-3",
                          attrs: { to: { name: "PageClaimRewardList" } },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "primary",
                                size: "20",
                                dense: "",
                              },
                            },
                            [_vm._v(" bi-person-square ")]
                          ),
                          _c("v-list-item-title", { staticClass: "ml-3" }, [
                            _vm._v(" Claim "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "white mx-4 rounded ml-8 mr-3",
                          attrs: { to: { name: "PageTransactionList" } },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "primary",
                                size: "20",
                                dense: "",
                              },
                            },
                            [_vm._v(" bi-layout-text-sidebar ")]
                          ),
                          _c("v-list-item-title", { staticClass: "ml-3" }, [
                            _vm._v(" Points "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "white mx-4 rounded ml-8 mr-3",
                          attrs: { to: { name: "PageDemonstrationList" } },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "primary",
                                size: "20",
                                dense: "",
                              },
                            },
                            [_vm._v(" bi-layout-text-sidebar ")]
                          ),
                          _c("v-list-item-title", { staticClass: "ml-3" }, [
                            _vm._v(" Demo Request "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-list-group",
                    {
                      attrs: { value: true },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function () {
                            return [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: "primary",
                                        light: "",
                                        size: "20",
                                        dense: "",
                                      },
                                    },
                                    [_vm._v(" bi-person-square ")]
                                  ),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(" Referral "),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "white mx-4 rounded ml-8 mr-3",
                          on: { click: _vm.showNotAllowed },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "primary",
                                size: "20",
                                dense: "",
                              },
                            },
                            [_vm._v(" bi-gift ")]
                          ),
                          _c("v-list-item-title", { staticClass: "ml-3" }, [
                            _vm._v(" Reward "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "white mx-4 rounded ml-8 mr-3",
                          on: { click: _vm.showNotAllowed },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "primary",
                                size: "20",
                                dense: "",
                              },
                            },
                            [_vm._v(" bi-person-square ")]
                          ),
                          _c("v-list-item-title", { staticClass: "ml-3" }, [
                            _vm._v(" Claim "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "white mx-4 rounded ml-8 mr-3",
                          on: { click: _vm.showNotAllowed },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "primary",
                                size: "20",
                                dense: "",
                              },
                            },
                            [_vm._v(" bi-layout-text-sidebar ")]
                          ),
                          _c("v-list-item-title", { staticClass: "ml-3" }, [
                            _vm._v(" Transaction "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "white mx-4 rounded ml-8 mr-3",
                          attrs: { to: { name: "PageDemonstrationList" } },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "primary",
                                size: "20",
                                dense: "",
                              },
                            },
                            [_vm._v(" bi-layout-text-sidebar ")]
                          ),
                          _c("v-list-item-title", { staticClass: "ml-3" }, [
                            _vm._v(" Demo Request "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _vm.allowedRolesReport.some(function (role) {
                return this$1.$store.getters.getRole.includes(role)
              })
                ? _c(
                    "v-list-group",
                    {
                      attrs: { value: true },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color: "primary",
                                          light: "",
                                          size: "20",
                                          dense: "",
                                        },
                                      },
                                      [_vm._v(" bi-calendar3-range ")]
                                    ),
                                    _c("span", { staticClass: "ml-2" }, [
                                      _vm._v(" Survey "),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1805410743
                      ),
                    },
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "white mx-4 rounded ml-8 mr-3",
                          attrs: { to: { name: "PageUserDetailSurveyList" } },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "primary",
                                size: "20",
                                dense: "",
                              },
                            },
                            [_vm._v(" bi-calendar3-range ")]
                          ),
                          _c("v-list-item-title", { staticClass: "ml-3" }, [
                            _vm._v(" User Detail "),
                          ]),
                        ],
                        1
                      ),
                      this.$store.getters.getFeatures.survey != null
                        ? _c(
                            "div",
                            [
                              this.$store.getters.getFeatures.survey.status ==
                                1 ||
                              (this.$store.getters.getFeatures.survey.beta ==
                                1 &&
                                this.$store.getters.getRole.includes(
                                  "developer"
                                ))
                                ? _c(
                                    "v-list-item",
                                    {
                                      staticClass:
                                        "white mx-4 rounded ml-8 mr-3",
                                      attrs: {
                                        to: { name: "PageFeedbackSurveyList" },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: "primary",
                                            size: "20",
                                            dense: "",
                                          },
                                        },
                                        [_vm._v(" bi-calendar3-range ")]
                                      ),
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "ml-3" },
                                        [_vm._v(" Feedback ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              this.$store.getters.getFeatures.survey.status ==
                                1 ||
                              (this.$store.getters.getFeatures.survey.beta ==
                                1 &&
                                this.$store.getters.getRole.includes(
                                  "developer"
                                ))
                                ? _c(
                                    "v-list-item",
                                    {
                                      staticClass:
                                        "white mx-4 rounded ml-8 mr-3",
                                      attrs: {
                                        to: { name: "PageFinanceSurveyList" },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: "primary",
                                            size: "20",
                                            dense: "",
                                          },
                                        },
                                        [_vm._v(" bi-calendar3-range ")]
                                      ),
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "ml-3" },
                                        [_vm._v(" Finance ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _c(
                    "v-list-group",
                    {
                      attrs: { value: true },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function () {
                            return [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: "primary",
                                        light: "",
                                        size: "20",
                                        dense: "",
                                      },
                                    },
                                    [_vm._v(" bi-calendar3-range ")]
                                  ),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(" Survey "),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "white mx-4 rounded ml-8 mr-3",
                          on: { click: _vm.showNotAllowed },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "primary",
                                size: "20",
                                dense: "",
                              },
                            },
                            [_vm._v(" bi-calendar3-range ")]
                          ),
                          _c("v-list-item-title", { staticClass: "ml-3" }, [
                            _vm._v(" User Detail "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "white mx-4 rounded ml-8 mr-3",
                          on: { click: _vm.showNotAllowed },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "primary",
                                size: "20",
                                dense: "",
                              },
                            },
                            [_vm._v(" bi-calendar3-range ")]
                          ),
                          _c("v-list-item-title", { staticClass: "ml-3" }, [
                            _vm._v(" Feedback "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "white mx-4 rounded ml-8 mr-3",
                          on: { click: _vm.showNotAllowed },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "primary",
                                size: "20",
                                dense: "",
                              },
                            },
                            [_vm._v(" bi-calendar3-range ")]
                          ),
                          _c("v-list-item-title", { staticClass: "ml-3" }, [
                            _vm._v(" Finance "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c(
                "v-list-group",
                {
                  attrs: { value: true },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c(
                            "v-list-item",
                            {},
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    ligth: "",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-paperclip ")]
                              ),
                              _c("span", { staticClass: "ml-2" }, [
                                _vm._v(" Attachments "),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "v-list-item",
                    {
                      staticClass: "white ml-8 mr-3",
                      attrs: { to: { name: "PageReceiptList" } },
                      on: { click: _vm.redirectReceipt },
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "primary", size: "20", dense: "" } },
                        [_vm._v(" bi-receipt ")]
                      ),
                      _c("v-list-item-title", { staticClass: "ml-3" }, [
                        _vm._v(" Proof of Payment "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      staticClass: "white ml-8 mr-3",
                      attrs: { to: { name: "PageInvoiceList" } },
                      on: { click: _vm.redirectInvoice },
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "primary", size: "20", dense: "" } },
                        [_vm._v(" bi-receipt ")]
                      ),
                      _c("v-list-item-title", { staticClass: "ml-3" }, [
                        _vm._v(" Invoices "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      staticClass: "white ml-8 mr-3",
                      attrs: { to: { name: "PageRefundList" } },
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "primary", size: "20", dense: "" } },
                        [_vm._v(" bi-receipt ")]
                      ),
                      _c("v-list-item-title", { staticClass: "ml-3" }, [
                        _vm._v(" Refund "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  staticClass: "white mx-4 rounded",
                  attrs: {
                    href:
                      _vm.$env.VUE_APP_URL_YYC_CRM +
                      "/en/vision/expressLogin?token=" +
                      _vm.auth.token,
                    target: "_blank",
                    disabled: _vm.$env.VUE_APP_ENV != "dev",
                  },
                },
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "primary", size: "20", dense: "" } },
                    [_vm._v(" bi-file-text-fill ")]
                  ),
                  _c("v-list-item-title", { staticClass: "ml-3" }, [
                    _vm._v(" HRDC "),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-group",
                {
                  attrs: { value: true },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c(
                            "v-list-item",
                            {},
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    ligth: "",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post-fill ")]
                              ),
                              _c("v-list-item-title", { staticClass: "mx-3" }, [
                                _vm._v(" Reports "),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm.allowedRolesReport.some(function (role) {
                    return this$1.$store.getters.getRole.includes(role)
                  })
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              attrs: { to: { name: "PageCompaniesReport" } },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" Companies "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              attrs: { to: { name: "PageMarketingReport" } },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" Marketing "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              attrs: { to: { name: "PageReportRenewalList" } },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" Renewal "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              attrs: { to: { name: "PageReApplyAAECoupon" } },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" Failed Coupons "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              attrs: { to: { name: "PageAnalysis" } },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" Analysis "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              attrs: { to: { name: "PageDaysAnalysis" } },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" 7 Days "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              attrs: { to: { name: "PageUserLastLoginList" } },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" Last Login "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              attrs: { to: { name: "PageLoginAnalysis" } },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass: "ml-3",
                                  staticStyle: { "white-space": "normal" },
                                },
                                [_vm._v(" Login Analysis ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              attrs: { to: { name: "PagePICReport" } },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" PIC "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              attrs: {
                                to: { name: "PageUnassignedCompanies" },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass: "ml-3",
                                  staticStyle: { "white-space": "normal" },
                                },
                                [_vm._v(" Unassigned Companies ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              on: { click: _vm.showNotAllowed },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" Companies "),
                              ]),
                            ],
                            1
                          ),
                          this.$store.getters.getRole.includes("marketing")
                            ? _c(
                                "v-list-item",
                                {
                                  staticClass: "white ml-8 mr-3",
                                  attrs: {
                                    to: { name: "PageMarketingReport" },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: "primary",
                                        size: "20",
                                        dense: "",
                                      },
                                    },
                                    [_vm._v(" bi-file-earmark-post ")]
                                  ),
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "ml-3" },
                                    [_vm._v(" Marketing ")]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "v-list-item",
                                {
                                  staticClass: "white ml-8 mr-3",
                                  on: { click: _vm.showNotAllowed },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: "primary",
                                        size: "20",
                                        dense: "",
                                      },
                                    },
                                    [_vm._v(" bi-file-earmark-post ")]
                                  ),
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "ml-3" },
                                    [_vm._v(" Marketing ")]
                                  ),
                                ],
                                1
                              ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              on: { click: _vm.showNotAllowed },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" Renewal "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              on: { click: _vm.showNotAllowed },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" New Subscription "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              on: { click: _vm.showNotAllowed },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" Failed Coupons "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              on: { click: _vm.showNotAllowed },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" Analysis "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              on: { click: _vm.showNotAllowed },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" 7 Days "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              on: { click: _vm.showNotAllowed },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" Last Login "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              on: { click: _vm.showNotAllowed },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" Login Analysis "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              on: { click: _vm.showNotAllowed },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" PIC "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "white ml-8 mr-3",
                              on: { click: _vm.showNotAllowed },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-file-earmark-post ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" Unassigned Companies "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ]
              ),
              this.$store.getters.getFeatures.dropdown != null
                ? _c(
                    "div",
                    [
                      this.$store.getters.getFeatures.dropdown.status == 1 ||
                      (this.$store.getters.getFeatures.dropdown.beta == 1 &&
                        this.$store.getters.getRole.includes("developer"))
                        ? _c(
                            "v-list-item",
                            {
                              staticClass: "white mx-4 rounded",
                              attrs: { to: { name: "PageDropdownList" } },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-menu-app ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" Dropdown "),
                              ]),
                            ],
                            1
                          )
                        : _c(
                            "v-list-item",
                            {
                              staticClass: "white mx-4 rounded",
                              on: { click: _vm.showNotAllowed },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "primary",
                                    size: "20",
                                    dense: "",
                                  },
                                },
                                [_vm._v(" bi-menu-app ")]
                              ),
                              _c("v-list-item-title", { staticClass: "ml-3" }, [
                                _vm._v(" Dropdown "),
                              ]),
                            ],
                            1
                          ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "white mx-4 rounded",
                          on: { click: _vm.showNotAllowed },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "primary",
                                size: "20",
                                dense: "",
                              },
                            },
                            [_vm._v(" bi-menu-app ")]
                          ),
                          _c("v-list-item-title", { staticClass: "ml-3" }, [
                            _vm._v(" Dropdown "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c(
                "v-list-item",
                {
                  staticClass: "white  mx-4 rounded ",
                  attrs: { to: { name: "PageManualList" } },
                  on: { click: _vm.redirectManual },
                },
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "primary", size: "20", dense: "" } },
                    [_vm._v(" bi-file-text ")]
                  ),
                  _c("v-list-item-title", { staticClass: "ml-3" }, [
                    _vm._v(" Manuals "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }